.title input {
  font-size: 20px;
}

.content {
  margin: 50px 0 10px 0;
  padding-bottom: 10px;
  border: none;
  border-bottom: 1px solid dimgrey;
  background-color: inherit;
  font-size: 20px;
  font-family: Roboto;
  outline: none;

}


.light .content span {
  background-color: darkblue;
  color: white;
}

.dark .content span {
  background-color: black;
  color: white;
}

.content span {
  white-space: nowrap;
  font-family: Roboto;
  display: inline-block;
  border-radius: 7px;
}


.light .dialogTextsList {
  background-color: darkblue;
}

.dark .dialogTextsList {
  background-color: black;
}

.textsListWrapper {
  display: none;
  position: absolute;
  z-index: 1000;
}

.dialogTextsList {
  margin: 0;
  text-align: center;
  vertical-align: middle;
  padding: 0;
  min-width: 150px;
  max-width: 200px;
  list-style: none;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.linkWrapper {
  display: flex;
  align-items: center;
}

.linkWrapper span {
  white-space: nowrap; /* Запрещаем перенос строк */
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  text-overflow: ellipsis; /* Добавляем многоточие */
}

.linkWrapper .textIcon {
  margin-right: 10px;
}

.light .dialogTextsList::after {
  content: '';
  top: -7px;
  left: 10%;
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 7px solid darkblue;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}

.dark .dialogTextsList::after {
  content: '';
  top: -7px;
  left: 10%;
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 7px solid black;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}

.dialogTextsItem {
  color: white;
  text-align: center;
  font-family: Roboto;
  padding: 10px;
  font-size: 15px;
  text-transform: uppercase;
}

.light .dialogTextsItem:hover {
  background-color: black;
  color: white;
}

.dark .dialogTextsItem:hover {
  background-color: white;
  color: black;
}

.light .linkToResource , .dark .linkToResource  {
  color: white;
}

.linkToResource::before {
  border-bottom: 1px solid white!important;
}


/* крестик для удаления привязки текста */
.close {
  position: relative;
  margin: 0 0 0 10px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  opacity: 0.5;
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  top: 8px;
  right: 15px;
  content: ' ';
  height: 17px;
  width: 2px;
  background-color: white;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
/* крестик удаления привязки текста */


/* подсказака о выбранном тексте */

.light .tooltip {
  color: white;
  background: grey;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.dark .tooltip {
  color: white;
  background: grey;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.tooltip {
  z-index: 1000;
  display: none;
  position: fixed;
  padding: 10px 20px;
  border-radius: 4px;
  text-align: center;
}

.dark .tooltip::after {
  z-index: 1000;
  content: '';
  top: -7px;
  left: 10%;
  position: absolute;
  width: 0;
  height: 0;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid grey;
}

.light .tooltip::after {
  z-index: 1000;
  content: '';
  top: -7px;
  left: 10%;
  position: absolute;
  width: 0;
  height: 0;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid grey;
}
/* подсказака о выбранном тексте */

.textLink {
  display: inline-block;
  padding: 3px;
  background-color: paleturquoise;
}



