html, body {
    margin: 0;
    padding: 0;
}

body {

}


/* не поддерживается в mozilla */
/*::-webkit-scrollbar { width: 2px; height: 3px;}*/
/*::-webkit-scrollbar-button {  background-color: #2B2B2B; height: 5px; }*/
/*::-webkit-scrollbar-track {  background-color: #999;}*/
/*::-webkit-scrollbar-track-piece { background-color: lightgrey;}*/
/*::-webkit-scrollbar-thumb { height: 10px; background-color: #2B2B2B; border-radius: 3px;}*/
/*::-webkit-scrollbar-corner { background-color: #999;}*/
/*::-webkit-resizer { background-color: #666;}*/
